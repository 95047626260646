<template>
  <div class="navbar-header d-xl-block d-none">
    <ul class="nav navbar-nav">
      <li class="nav-item">
        <b-link
          class="navbar-brand"
          to="/"
        >
          <span class="brand-logo">
            <b-img
              :src="logoPreview"
              alt="logo"
            />
          </span>
          <h2 class="brand-text mb-0">
            {{ name }}
          </h2>
        </b-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { BLink, BImg } from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'

export default {
  components: {
    BLink,
    BImg,
  },
  data() {
    return {
      logo: [],
      banner: [],
      logoPreview: null,
      bannerPreview: null,
      name: '',
      description: '',
      isBusy: false,
    }
  },
  methods: {
    getPersonalizacao() {
      // Verificar se a logo e o banner já estão no LocalStorage
      const logo = localStorage.getItem('logo');
      const banner = localStorage.getItem('banner');

      if (logo && banner && logo !== "undefined" && banner !== "undefined") {
        // Se existirem no LocalStorage, atribuir os valores diretamente às variáveis
        this.logoPreview = logo;
        this.bannerPreview = banner;
        this.name = localStorage.getItem('name');
        this.description = localStorage.getItem('description');
      } else {
        // Caso contrário, fazer a solicitação GET e salvar os valores no LocalStorage
        axios.get(`${process.env.VUE_APP_API}/personalizar`)
          .then(response => {
            console.log(response.data);
            this.logoPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.logo}`;
            this.bannerPreview = `${process.env.VUE_APP_API_BASE}/storage/${response.data.response.banner}`;
            this.name = response.data.response.name;
            this.description = response.data.response.description;

            // Salvar os valores no LocalStorage para uso futuro
            localStorage.setItem('logo', this.logoPreview);
            localStorage.setItem('banner', this.bannerPreview);
            localStorage.setItem('name', this.name);
            localStorage.setItem('description', this.description);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
  },
  beforeMount() {
    this.getPersonalizacao()
  },
  setup() {
    // App Name
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
}
</script>

<style>

</style>
